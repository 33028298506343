import { type FC } from 'react'
import { Page } from '@lib/components'
import { Link } from 'react-router-dom'
import { Header, WithFooter } from '~/components'

export const NotFound: FC = () => {
  return <WithFooter>
    <Header />
    <div className="my-6 mx-4">
      <Link className="underline text-mtn-blue-700" to="/">&lt; Go Home</Link>
    </div>
    <Page className="mt-10" skinny>
      <h1 className="text-xl font-medium font-bold text-mtn-blue-800">This page could not be found</h1>
    </Page>
  </WithFooter>
}
