import { type FC } from 'react'
import { useAuth } from '@lib/hooks'
import { MTNtracking } from '@shamaazi/mytennights'

const ShareTracker: FC<{ type: string, isDashboard: boolean, url?: string, lengthChoice?: string, splitChoice?: string, giftAid?: boolean, wakf?: boolean, wakfAmount?: number, fridayGiver?: boolean, fridayGiverAmount?: number }> = ({ children, type, url, isDashboard, lengthChoice, splitChoice, giftAid, wakf, wakfAmount, fridayGiver, fridayGiverAmount }) => {
  const { user } = useAuth()
  const handleClick = (): void => {
    MTNtracking.socialShareButton(user?.email, type, isDashboard, url, lengthChoice, splitChoice, giftAid, wakf, wakfAmount, fridayGiver, fridayGiverAmount)
  }

  return <div onClick={() => handleClick()}>
    {children}
  </div>
}

export default ShareTracker
