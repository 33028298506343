import { useSessionStorage } from '@lib/hooks'
import { Currency } from '@lib/services'

export interface ShareDetails {
  shareCode: string
  email: string
  total: number
  matchedAmount: number
  matchPotBalance: number
  currency: Currency
  firstName: string
  lengthChoice?: string
  splitChoice?: string
  giftAid?: boolean
  fridayGiver?: boolean
  fridayGiverAmount?: number
  wakf?: boolean
  wakfAmount?: number
}

export const getDefaultDetails = (): ShareDetails => {
  return {
    total: 0,
    matchedAmount: 0,
    shareCode: '',
    email: '',
    matchPotBalance: 0,
    currency: Currency.GBP,
    firstName: '',
    lengthChoice: '',
    splitChoice: ''
  }
}

// TODO fix setSharePageDetails; its not working as expected
export const useSharePageDetails = (): { sharePageDetails: ShareDetails, setSharePageDetails: (d: ShareDetails) => void } => {
  const [sharePageDetails, setSharePageDetails] = useSessionStorage<ShareDetails>('share_page_details', getDefaultDetails())

  return {
    sharePageDetails,
    setSharePageDetails
  }
}
