import { useEffect, useState, type FC } from 'react'
import { Header, WithFooter } from '~/components'
import DAFProcess from '../img/mtn-daf-process.png'
import DAFProcessMobile from '../img/mtn-how-money-flows.png'
import download from '../img/download.png'
import openQuote from '../img/open_quote.png'
import closeQuote from '../img/close_quote.png'
import { isMobileScreen } from '@shamaazi/mytennights'
export const Fees: FC = () => {
  const [isMobile, setIsMobile] = useState(isMobileScreen())
  useEffect(() => {
    window.addEventListener('resize', () => setIsMobile(isMobileScreen()))
    return window.removeEventListener('resize', () => setIsMobile(isMobileScreen()))
  })
  return <WithFooter>
        <Header transparent />
        <section>
            <div className='bg-top bg-no-repeat bg-cover mountain-sky-full'>
                <h1 className="mx-10 md:mx-0 pt-28 text-4xl text-center font-semibold md:text-4xl lg:text-7xl text-white">100% Donation Policy</h1>
                <p className='mx-20 md:mx-0 font-bold text-center pt-5 text-xl md:text-xl lg:text-3xl text-white'>We never touch your donation</p>
                {isMobile && <div className="mt-10 px-10">
                    <img src={DAFProcessMobile} alt="daf process" />
                </div>}
                {!isMobile && <div className="mt-10 px-10">
                    <img src={DAFProcess} alt="daf process" />
                </div>}
                <br />
                <br />
            </div>
        </section>
        <div className='mx-7 md:mx-20 lg:mx-72 text-gm-black'>
            <section id="about">
                <p className="mt-10 text-center text-lg md:text-sm lg:text-2xl">At MyTenNights, <b>100% of your donation reaches your chosen charity,</b> with the only deduction being the card processing fees for payment processing. We encourage donors to cover this fee themselves, ensuring that the full amount of their original donation reaches the charity.</p>
                <p className="mt-4 text-center text-lg md:text-sm lg:text-2xl">To enhance the security and transparency of your charitable contributions, we utilise Donor Advised Funds (DAF), offering a secure and transparent method of donation. This means <b>we are entirely outside of the money flow,</b> and at no point ever touch or have control over your money. Your money travels directly from your bank into a charity account. This approach secures the donation process and guarantees that your donation always reaches its intended destination.</p>
                <p className="mt-4 text-center text-lg md:text-sm lg:text-2xl">Our partnership with DAFs underscores our commitment to integrity in philanthropy, <b>making giving seamless, secure, and meaningful.</b> Below are our DAF partners:</p>
                <div className='flex flex-col md:flex-row justify-between gap-x-8 lg:gap-x-20 mt-10 lg:mx-32'>
                    <div className='flex flex-col w-full items-center text-center text-sm lg:text-xl'>
                        <p>For Canadian donations:</p>
                        <b className='mt-5'>Myriad Canada (Formerly KBF Canada)</b>
                        <p>Registered Charitable Organisation</p>
                        <p>No. 76978 4893 RR0001</p>
                    </div>
                    <div className='flex flex-col mt-10 md:mt-0 w-full items-center text-center text-sm lg:text-xl'>
                        <p>For UK donations:</p>
                        <b className='mt-5'>Mercy Mission UK</b>
                        <p>Registered Charitable Organisation</p>
                        <p>No. 1122922</p>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row justify-between gap-x-8 lg:gap-x-20 mt-10 lg:mx-32'>
                    <div className='flex flex-col w-full items-center text-center text-sm lg:text-xl'>
                        <p>For USA donations:</p>
                        <b className='mt-5'>Myriad USA (Formerly The King Baudouin Foundation)</b>
                        <p>Registered Charitable Organisation</p>
                        <p>No. 58-2277856</p>
                    </div>
                    <div className='flex flex-col w-full items-center text-center mt-10 md:mt-0 text-sm lg:text-xl'>
                        <p>For Australian donations:</p>
                        <b className='mt-5'>Myriad Australia</b>
                        <p>Registered Charitable Organisation</p>
                        <p>ABN 20 640 318 636</p>
                    </div>
                </div>
            </section>
        </div>
        <div className='mx-7 md:mx-16 lg:mx-52 text-gm-black'>
            <section id="about">
                <p className="mt-10 md:mt-16 text-2xl font-bold md:text-3xl lg:text-5xl text-center">How this benefits</p>
                <p className="lg:my-2 text-2xl font-bold md:text-3xl lg:text-5xl text-center">donors and charities</p>
                <div className='flex flex-col md:flex-row gap-x-14 lg:gap-x-20 mt-5 md:mt-10'>
                    <div className='flex flex-col w-full items-center text-center'>
                        <b className='mt-5 text-xl md:text-xl lg:text-xl'>Transparency & Trust</b>
                        <p className='text-center mt-5 text-sm lg:text-lg'>DAFs act as a transparent intermediary between donors and charities, meticulously tracking and verifying available funds for matching, ensuring your donations are always safe and put to good use.</p>
                    </div>
                    <div className='flex flex-col w-full items-center text-center'>
                        <b className='mt-5 text-xl md:text-xl lg:text-xl'>Tax Benefits & Receipts</b>
                        <p className='text-center mt-5 text-sm lg:text-lg'>DAFs provide immediate tax benefits for your contributions and issue donor tax receipts, making giving not only impactful but also beneficial for you.</p>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row gap-x-14 lg:gap-x-20 md:mt-10'>
                    <div className='flex flex-col w-full items-center text-center'>
                        <b className='mt-5 text-xl md:text-xl lg:text-xl'>Global Reach, Local Impact</b>
                        <p className='text-center mt-5 text-sm lg:text-lg'>Through partnerships with DAFs worldwide, we connect the charities with a regional partner upon registration, offering a localised and efficient donation process.</p>
                    </div>
                    <div className='flex flex-col w-full items-center text-center'>
                        <b className='mt-5 text-xl md:text-xl lg:text-xl'>Accountability & Ethics</b>
                        <p className='text-center mt-5 text-sm lg:text-lg'>We prioritise the ethical use of funds, conducting thorough due diligence on funders and charities alike, guaranteeing your support reaches trusted organisations.</p>
                    </div>
                </div>
            </section>
        </div>

        <div className='mx-7 mb-14 md:mx-16 lg:mx-52 text-gm-black'>
            <section id="donation">
                <p className="mt-10 md:mt-16 text-xl text-center font-bold md:text-3xl lg:text-5xl">How does MyTenNights</p>
                <p className="text-xl mb-5 md:mb-16 text-center font-bold md:text-3xl lg:text-5xl">make money?</p>
                <div className='md:w-4/5 md:m-auto'>
                    <p className="mt-4 text-center text-sm lg:text-2xl">MyTenNights operates on a tip system, whereby <b>we rely on the generosity of our donors </b>to top up their donation with an optional tip. This helps us fund our tech and operations, allowing us to smoothly and efficiently offer you a free service so you can catch the blessings of Laylatul Qadr and beyond.</p>
                    <p className="mt-4 text-center text-sm lg:text-2xl"><b> 100% of your donation goes directly to your chosen charity, </b>via our regulator DAF partner, so nothing is ever subtracted from it. Please read testimonials from some of our charity partners below.</p>
                </div>
            </section>
        </div>
        <div className='mx-7 mb-20 md:mx-16 lg:mx-52 text-gm-black'>
            <section id="donation">
                <p className="mt-7 mb-5 md:mb-16 text-xl text-center font-bold md:text-3xl lg:text-5xl">Hear from our charities</p>
                <div className='md:w-4/5 md:m-auto flex flex-row'>
                    <div><img src={openQuote} className="w-32" alt="openQuote" /></div>
                    <div>
                        <p className="mt-4 text-center text-sm lg:text-2xl">All funds raised for Islamic Relief UK on the ‘My Ten Nights’ platform are <b>transferred to Islamic Relief UK in whole with no deductions taken by the platform from any donations.</b> The only deduction is a banking charge which is charged by the debit or credit card company or bank you use to make your donation.</p>
                    </div>
                    <div className='self-end'><img src={closeQuote} className="w-32" alt="closeQuote" /></div>
                </div>
                <p className="mt-7 text-center text-sm lg:text-lg">Islamic Relief - UK</p>
                <div className='flex flex-row items-end justify-center gap-x-1'>
                    <a href='https://mtn-testimonials-pdf.s3.eu-west-2.amazonaws.com/testimony_islamic-relief-uk.pdf' target="_blank" download="Testimony_Islamic_Relief.pdf" className="flex items-center mt-2 md:mt-4 text-center text-sm" rel="noreferrer">
                        <img src={download} className="h-5 w-5" alt="download" />
                        <b className='pl-2'>Download full testimony</b>
                    </a>
                </div>
                <div className='pt-14 md:w-4/5 md:m-auto flex flex-row'>
                    <div><img src={openQuote} className="w-16" alt="openQuote" /></div>
                    <div>
                        <p className="mt-4 text-center text-sm lg:text-2xl">
                        We affirm that MyTenNights, our trusted fundraising platform, operates under a fee-free model for charitable organizations such as ours. This means that
                         <b> MyTenNights does not levy any fees or charges on the donations processed</b> through their platform on behalf of Muslim Aid.</p>
                    </div>
                    <div className='self-end'><img src={closeQuote} className="w-16" alt="closeQuote" /></div>
                </div>
                <p className="mt-7 text-center text-sm lg:text-lg">MuslimAid - UK</p>
                <div className='flex flex-row items-end justify-center gap-x-1'>
                    <a href='https://mtn-testimonials-pdf.s3.eu-west-2.amazonaws.com/testimony_muslim-aid-uk.pdf' target="_blank" download="Testimony_MuslimAid.pdf" className="flex items-center mt-2 md:mt-4 text-center text-sm" rel="noreferrer">
                        <img src={download} className="h-5 w-5" alt="download" />
                        <b className='pl-2'>Download full testimony</b>
                    </a>
                </div>
                <div className='pt-14 md:w-4/5 md:m-auto flex flex-row'>
                    <div><img src={openQuote} className="w-16" alt="openQuote" /></div>
                    <div>
                        <p className="mt-4 text-center text-sm lg:text-2xl">
                            We wish to reiterate to our donors that donations made to Orphans in Need via My Ten Nights are <b>not subject to deductions before reaching us.</b></p>
                    </div>
                    <div className='self-end'><img src={closeQuote} className="w-16" alt="closeQuote" /></div>
                </div>
                <p className="mt-7 text-center text-sm lg:text-lg">Orphans in Need - UK</p>
                <div className='flex flex-row items-end justify-center gap-x-1'>
                    <a href='https://mtn-testimonials-pdf.s3.eu-west-2.amazonaws.com/testimony_orphans-in-need-uk.pdf' target="_blank" download="Testimony_Orphans_in_Need.pdf" className="flex items-center mt-2 md:mt-4 text-center text-sm" rel="noreferrer">
                        <img src={download} className="h-5 w-5" alt="download" />
                        <b className='pl-2'>Download full testimony</b>
                    </a>
                </div>
                <div className='pt-14 md:w-4/5 md:m-auto flex flex-row'>
                    <div><img src={openQuote} className="w-20" alt="openQuote" /></div>
                    <div>
                        <p className="mt-4 text-center text-sm lg:text-2xl">It is with immense gratitude that I acknowledge the commitment made by My Ten Nights to support our organisation.
                            <br />
                            <br />
                            We at Naseeha are incredibly pleased to confirm that <b>we are receiving 100% of the funds raised through the platform
                                provided by MyTenNights.</b></p>
                    </div>
                    <div className='self-end'><img src={closeQuote} className="w-20" alt="closeQuote" /></div>
                </div>
                <p className="mt-7 text-center text-sm lg:text-lg">Naseeha Mental Health - Canada</p>
                <div className='flex flex-row items-end justify-center gap-x-1'>
                    <a href='https://mtn-testimonials-pdf.s3.eu-west-2.amazonaws.com/testimony_naseeha.pdf' download="Testimony_Naseeha.pdf" target="_blank" className="flex items-center mt-2 md:mt-4 text-center text-sm" rel="noreferrer">
                        <img src={download} className="h-5 w-5" alt="download" />
                        <b className='pl-2'>Download full testimony</b>
                    </a>
                </div>
            </section>
        </div>
        <div className='mx-7 mb-20 md:mx-16 lg:mx-52 text-gm-black'>
            <section id="donation">
                <p className="mt-7 mb-5 md:mb-16 text-xl text-center font-bold md:text-3xl lg:text-5xl">Hear from our charity regulators</p>
                <div className='md:w-4/5 md:m-auto flex flex-row'>
                    <div><img src={openQuote} className="w-32" alt="openQuote" /></div>
                    <div>
                        <p className="mt-4 text-center text-sm lg:text-2xl">We are pleased to confirm our partnership with MyTenNights, aimed at bolstering the security and transparency of your charitable donations.
                             <b> We serve as a transparent intermediary, guaranteeing proper handling of your contributions. </b>
                             Our commitment to <b>ethical fund management is paramount</b>, as we meticulously conduct due diligence to ensure your support reaches reputable organizations</p>
                    </div>
                    <div className='self-end'><img src={closeQuote} className="w-32" alt="closeQuote" /></div>
                </div>
                <p className="mt-7 text-center text-sm lg:text-lg">Myriad USA</p>
                <div className='flex flex-row items-end justify-center gap-x-1'>
                    <a href='https://mtn-testimonials-pdf.s3.eu-west-2.amazonaws.com/testimony_regulator-usa.pdf' target="_blank" download="testimony-regulator-usa.pdf" className="flex items-center mt-2 md:mt-4 text-center text-sm" rel="noreferrer">
                        <img src={download} className="h-5 w-5" alt="download" />
                        <b className='pl-2'>Download full testimony</b>
                    </a>
                </div>
                <div className='pt-14 md:w-4/5 md:m-auto flex flex-row'>
                    <div><img src={openQuote} className="w-16" alt="openQuote" /></div>
                    <div>
                        <p className="mt-4 text-center text-sm lg:text-2xl">
                            Through our partnership with MyTenNights, we guarantee that 100% of your donations,
                            minus any 3rd party fees, are onwards gifted to the designated charities.
                            <br />
                            <br/>
                            All<b> funds flow directly from your account into our charity account</b>, which is managed by
only us, the DAF partner. MyTenNights never handles any of your donations and <b>100% of
donations are sent to us in full.</b>
                            </p>
                    </div>
                    <div className='self-end'><img src={closeQuote} className="w-16" alt="closeQuote" /></div>
                </div>
                <p className="mt-7 text-center text-sm lg:text-lg">Myriad Australia</p>
                <div className='flex flex-row items-end justify-center gap-x-1'>
                    <a href='https://mtn-testimonials-pdf.s3.eu-west-2.amazonaws.com/testimony_regulator-australia.pdf' target="_blank" download="estimony-regulator-australia.pdf" className="flex items-center mt-2 md:mt-4 text-center text-sm" rel="noreferrer">
                        <img src={download} className="h-5 w-5" alt="download" />
                        <b className='pl-2'>Download full testimony</b>
                    </a>
                </div>
                <div className='pt-14 md:w-4/5 md:m-auto flex flex-row'>
                    <div><img src={openQuote} className="w-20" alt="openQuote" /></div>
                    <div>
                        <p className="mt-4 text-center text-sm lg:text-2xl">We have partnered with MyTenNights to ensure the safe handling of all of your donations.
                        <b> We serve as an added layer of security to ensure a secure and transparent method of donations</b>. We guarantee that 100% of your donations, minus any 3rd party fees, are onwards gifted to the chosen charities.
                            <br />
                            <br />
                            MyTenNights does not handle any donations; instead, <b>100% of your contribution is remitted to us in its entirety</b>, with the exception of any third-party fees.</p>
                    </div>
                    <div className='self-end'><img src={closeQuote} className="w-20" alt="closeQuote" /></div>
                </div>
                <p className="mt-7 text-center text-sm lg:text-lg">Myriad Canada</p>
                <div className='flex flex-row items-end justify-center gap-x-1'>
                    <a href='https://mtn-testimonials-pdf.s3.eu-west-2.amazonaws.com/testimony_regulator-canada.pdf' download="testimony-regulator-canada.pdf" target="_blank" className="flex items-center mt-2 md:mt-4 text-center text-sm" rel="noreferrer">
                        <img src={download} className="h-5 w-5" alt="download" />
                        <b className='pl-2'>Download full testimony</b>
                    </a>
                </div>
            </section>
        </div>
    </WithFooter>
}
