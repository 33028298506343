import { Button, Variant } from '@lib/components'
import {
  useCheckoutPlan,
  getSplitBreakdown,
  AutoEnroll,
  PaymentFormProps,
  scrollToHash,
  ScrollHash,
  MTNtracking,
  Ramadan,
  autoEnrollMTNErrorMsg,
  MessageBox,
  ActiveExperiments,
  TestVersion
} from '@shamaazi/mytennights'
import { type FC, useState, type ReactElement } from 'react'

import { useNavigate } from 'react-router-dom'
import { CheckoutPage, CheckoutProgress, CheckoutSummaryCard, Navigation, Pages, WithFooter } from '~/components'
import { MytennightsTestId } from '@lib/testing'
import { useAuth } from '@lib/hooks'
import { Header } from '~/components/header'
import arrowRight from '~/img/arrow-right.svg'
import CurrencyInput from 'react-currency-input-field'
import { Choice, GetCurrencyByString, getCurrencySymbol } from '@lib/services'
import { Experiment, useExperiment, Variant as SplitTestVariant } from '@lib/react-ab-test'
import { getTotalFees } from '~/service/fees'
import Flair from '~/components/Flair'

enum Component {
  FridayGiving = 'FridayGiving',
  Wakf = 'Wakf'
}
export const Details: FC<{ nextPage: string }> = ({ nextPage }) => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { plan, setPlan } = useCheckoutPlan()
  const [autoEnrolError, setAutoEnrolError] = useState(false)
  const [fGError, setFGError] = useState('')
  const [fridayGiver, setFridayGiver] = useState(plan.fridayGivingOptIn)
  const [fridayGivingAmount, setFridayGivingAmount] = useState(plan.fridayGivingOptIn === Choice.yes ? plan.fridayGiving : 1000)
  const [wakf, setWakf] = useState(plan.wakfOptIn)
  const [wakfAmount, setWakfAmount] = useState(plan.wakfOptIn === Choice.yes ? plan.wakf : 4000)
  const [wakfError, setWakfError] = useState('')

  const [charityEmailOptIn, setCharityEmailOptIn] = useState(plan.charityEmailOptIn)
  const [emailOptIn, setEmailOptIn] = useState(plan.emailOptIn)
  const breakdown = getSplitBreakdown(plan)
  const { activeVariant: maximiseBlessingVariant } = useExperiment(ActiveExperiments.MaximiseBlessingsPageTest)

  const componentPresentInExperiment = (component: Component): boolean => {
    if (component === Component.Wakf) {
      return [TestVersion.variantB, TestVersion.variantC].includes(maximiseBlessingVariant)
    }
    if (component === Component.FridayGiving) {
      return [TestVersion.variantA, TestVersion.variantC].includes(maximiseBlessingVariant)
    }
    return false
  }

  const renderContinueBtn = (): JSX.Element => {
    return <Button
      data-test-id={MytennightsTestId.continueButton}
      variant="mtn-donate-now"
      className='bg-gm-yellow border-gm-yellow text-mtn-blue-800 text-2xl py-3 w-72 uppercase font-bold '
      onClick={() => {
        let validationError = false
        if (componentPresentInExperiment(Component.Wakf)) {
          if (!plan.wakfOptIn) {
            setWakfError('Please let us know if you would be interested in Wakf')
            scrollToHash(ScrollHash.wakf)
            validationError = true
          }
          if (plan.wakfOptIn === Choice.yes && wakfAmount < 100) {
            setWakfError('Please enter a valid amount for your Wakf')
            scrollToHash(ScrollHash.wakf)
            validationError = true
          }
        }
        if (componentPresentInExperiment(Component.FridayGiving)) {
          if (!plan.fridayGivingOptIn) {
            setFGError('Please let us know if you would be interested in Friday Giving')
            scrollToHash(ScrollHash.fridayGiving)
            validationError = true
          }
          if (plan.fridayGivingOptIn === Choice.yes && fridayGivingAmount < 100) {
            setFGError('Please enter a valid amount for your Friday Giving')
            scrollToHash(ScrollHash.fridayGiving)
            validationError = true
          }
        }
        if (!plan.autoEnroll) {
          setAutoEnrolError(true)
          scrollToHash(ScrollHash.autoEnroll)
          validationError = true
        }
        if (validationError) {
          return
        }
        const firstNight = breakdown.nights.find((n) => n.isOpen)
        setPlan({ ...plan, firstNight: firstNight?.number ?? Ramadan.firstDonationNight, fridayGiving: plan.fridayGivingOptIn === Choice.yes ? fridayGivingAmount : 0, wakf: plan.wakfOptIn === Choice.yes ? wakfAmount : 0 })
        MTNtracking.confirmDetails(user?.email, plan, wakfAmount, fridayGivingAmount)
        const partner = MTNtracking.getPartner().partner
        navigate(Navigation.prefixPartnerApplicableRoute(nextPage, partner))
      }}
    >
      <div className='flex gap-x-4 justify-center'>
        Continue <img src={arrowRight} alt='continue' />
      </div>
    </Button>
  }

  const renderFridayGivingBoxVersion = (): ReactElement => {
    return <section id={ScrollHash.fridayGiving} className={` ${fGError ? 'bg-mtn-red-light border-mtn-red-dark ' : 'friday-giving-dark-bg-image'} flex flex-col p-6 rounded-lg bg-cover bg-no-repeat bg-center`}>
      <div className='flex flex-row justify-between relative'>
        <div className='flex flex-col my-1'>
          <h2 className={`${fGError ? 'text-gm-black ' : 'text-white'} text-2xl font-semibold`}> Become a Friday Giver </h2>
        </div>
        <MessageBox> Your donations to Friday Giving will go into the 'where most needed' pot. </MessageBox>
      </div>
      <div className='flex flex-row my-1'>
        <p className={`text-base ${fGError ? 'text-gm-black ' : 'text-white'} font-light mb-3`}> Automatically donate every Friday after Ramadan, so you <span className='font-semibold'>never miss the Hour of Acceptance!</span> Cancel anytime.</p>

      </div>

      <label className="flex items-center">
        <input
          data-test-id={MytennightsTestId.fridayGivingYesButton}
          type="radio"
          value="true"
          checked={fridayGiver === Choice.yes}
          className="mr-4 radioCheck-dark"
          onClick={() => {
            setFridayGiver(Choice.yes)
            setPlan({ ...plan, fridayGivingOptIn: Choice.yes })
          }} />
        <div className="flex flex-row items-center my-1 gap-x-4">
          <p className={`${fGError ? 'text-gm-black ' : 'text-white'} font-semibold mr-1`}>Yes</p>
          <div className='flex bg-mtn-gold text-mtn-blue-800 p-1 font-medium text-xs rounded items-center'>
            <span className='px-1'> POPULAR CHOICE </span>
            <Flair />
          </div>
        </div>
      </label>
      {fridayGiver === Choice.yes && <div
        className={'font-medium col-span-1'}>
        <div className="flex flex-col gap-y-6 justify-between items-left h-full">
          <form className="flex rounded-lg bg-white border-1 flex-row w-full">
            <span className='w-1/6 flex justify-center items-center px-2 text-2xl font-semibold text-right border-sz-gray-400 focus:outline-none '>{getCurrencySymbol(GetCurrencyByString(plan.charity.currency))}</span>
            <CurrencyInput
              data-test-id={MytennightsTestId.fridayGivingInput}
              id="friday giving amount"
              className="w-5/6 text-2xl rounded-lg font-bold p-2 py-1 focus:outline-none text-gm-black"
              placeholder={'0.00'}
              step={0.01}
              value={fridayGivingAmount / 100}
              onValueChange={(value) => {
                setFGError('')
                const valueInNumber = isNaN(Number(value)) ? 0 : Number(value)
                setPlan({ ...plan, fridayGiving: valueInNumber * 100 })
                setFridayGivingAmount(valueInNumber * 100)
              }
              }
            />
          </form>
        </div>
      </div>}
      <label className="flex items-center">
        <input
          data-test-id={MytennightsTestId.fridayGivingNoButton}
          type="radio"
          value="false"
          checked={fridayGiver === Choice.no}
          className="mr-4 radioCheck-dark"
          onClick={() => {
            setFridayGiver(Choice.no)
            setFGError('')
            setFridayGivingAmount(0)
            setPlan({ ...plan, fridayGivingOptIn: Choice.no, fridayGiving: 0 })
          }} />
        <div className="flex flex-col sm:flex-row my-1">
          <p className={`${fGError ? 'text-gm-black ' : 'text-white'} font-semibold mr-1`}>No</p>
        </div>
      </label>
      <div className='w-auto my-2'>
        <span className={`${fGError ? 'text-gm-black' : 'text-white'} font-thin text-sm`}> We take your donation one hour before your Friday Maghreb. </span>
      </div>
      <div className='w-auto my-2'>
        <span className={`${fGError ? 'text-gm-black' : 'text-mtn-gold'} font-thin text-sm italic`}> <span className=' font-bold'>Give charity on Friday, ‘there is an hour during which Allah will accept any supplication you make’ </span>- Prophet Muhammed PBUH </span>
      </div>
      {fGError !== '' && <span className="text-md text-left text-mtn-red min-h-xs mt-3"> {fGError} </span>}
    </section>
  }

  const renderWakfBox = (): ReactElement => {
    return <section id={ScrollHash.wakf} className={` ${wakfError ? 'bg-mtn-red-light border-mtn-red-dark ' : 'wakf-bg-image'} flex flex-col p-6 rounded-lg bg-cover bg-no-repeat bg-right-bottom`}>
      <div className='flex flex-row justify-between relative'>
        <div className='flex flex-col my-1'>
          <h2 className={`${wakfError ? 'text-gm-black ' : 'text-mtn-blue-800'} text-2xl font-semibold`}>

            <Experiment name={ActiveExperiments.MTNWakfTitle}>
              <SplitTestVariant name={TestVersion.control}>
                <>  Make your deeds work for you, forever! </>
              </SplitTestVariant>
              <SplitTestVariant name={TestVersion.variantA}>
                <> Become a Legacy Giver </>
              </SplitTestVariant>
              <SplitTestVariant name={TestVersion.variantB}>
                <> Want your rewards to last forever? </>
              </SplitTestVariant>
              <SplitTestVariant name={TestVersion.variantC}>
                <> Continue your rewards after you pass? </>
              </SplitTestVariant>
            </Experiment>
          </h2>
        </div>
        <MessageBox> <div className='text-xs flex flex-col gap-y-3'>
          <div>
            <p className='font-bold'>Who invests my donation?</p>
            <span className=' font-light'>Your donations are managed by an experienced team of portfolio managers at <a target='_blank' href='https://app.waqfinity.com/' className='underline' rel="noreferrer">Waqfinity</a>, and endorsed by Mercy Mission UK.</span>
          </div>
          <div>
            <p className='font-bold'>Can I follow how my donation is being invested?</p>
            <span className=' font-light'>Yes, transparency is a cornerstone of our operations. Donors can track their contributions and see the direct impact of their donations through regular updates and reports. </span>
          </div>
          <div>
            <p className='font-bold'>Where can I learn more?</p>
            <span className='font-light'><a target='_blank' href='mailto:contact@mytennights.com' className='underline' rel="noreferrer">Email us</a> for any queries you may have and we'll glad help. </span>
          </div>
        </div> </MessageBox>
      </div>
      <div className='flex flex-row my-1'>
        <p className={`text-base ${wakfError ? 'text-gm-black ' : 'text-mtn-blue-800'} font-light mb-3`}> Automate a monthly donation into property and the   <span className='font-semibold'>profits go to charity forever, so your rewards won’t stop after you pass.</span> Cancel anytime.</p>

      </div>

      <label className="flex items-center">
        <input
          data-test-id={MytennightsTestId.wakfYesButton}
          type="radio"
          value="true"
          checked={wakf === Choice.yes}
          className="mr-4 radioCheck-dark"
          onClick={() => {
            setWakf(Choice.yes)
            setPlan({ ...plan, wakfOptIn: Choice.yes })
            MTNtracking.changeWakfChoice(user?.email, plan, Choice.yes, wakfAmount)
          }} />
        <div className="flex flex-row items-center my-1 gap-x-4">
          <p className={`${wakfError ? 'text-gm-black ' : 'text-mtn-blue-800'} font-semibold mr-1`}>Yes</p>
          {<div className='flex bg-mtn-green text-white p-1 font-medium text-xs rounded items-center'>
            <span className='px-1'> POPULAR CHOICE </span>
            <Flair color='#fff' />
          </div>}
        </div>
      </label>
      {wakf === Choice.yes && <div
        className={'font-medium col-span-1'}>
        <div className="flex flex-col gap-y-6 justify-between items-left h-full">
          <form className="flex rounded-lg bg-white border-1 flex-row w-full">
            <span className='md:w-1/12 w-1/6 flex justify-center items-center px-2 text-xl text-right border-sz-gray-400 focus:outline-none '>{getCurrencySymbol(GetCurrencyByString(plan.charity.currency))}</span>
            <CurrencyInput
              data-test-id={MytennightsTestId.wakfInput}
              id="friday giving amount"
              className="w-2/4 text-2xl rounded-lg font-bold p-2 py-1 focus:outline-none text-gm-black"
              placeholder={'0.00'}
              step={0.01}
              value={wakfAmount / 100}
              onValueChange={(value) => {
                setWakfError('')
                const valueInNumber = isNaN(Number(value)) ? 0 : Number(value)
                setPlan({ ...plan, wakf: valueInNumber * 100 })
                setWakfAmount(valueInNumber * 100)
              }
              }
            />
            <span className='w-1/2 flex justify-end items-center px-4 text-xs border-sz-gray-400 focus:outline-none '>per month</span>
          </form>
        </div>
      </div>}
      <label className="flex items-center">
        <input
          data-test-id={MytennightsTestId.wakfNoButton}
          type="radio"
          value="false"
          checked={wakf === Choice.no}
          className="mr-4 radioCheck-dark"
          onClick={() => {
            setWakf(Choice.no)
            setWakfError('')
            setWakfAmount(0)
            setPlan({ ...plan, wakfOptIn: Choice.no, wakf: 0 })
            MTNtracking.changeWakfChoice(user?.email, plan, Choice.no, wakfAmount)
          }} />
        <div className="flex flex-col sm:flex-row my-1">
          <p className={`${wakfError ? 'text-gm-black ' : 'text-mtn-blue-800'} font-semibold mr-1`}>No</p>
        </div>
      </label>
      <div className='w-auto my-2 pb-6'>
        <span className={`${wakfError ? 'text-gm-black' : ' text-mtn-green'} font-thin text-sm italic`}> <span className=' font-bold'>‘When a Muslim dies all their rewards stop, except for their ongoing charity...’  <br /></span> Prophet (PBUH) </span>
      </div>
      {wakfError !== '' && <span className="text-md text-left text-mtn-red min-h-xs mt-3"> {wakfError} </span>}
    </section>
  }

  return (
    <WithFooter navigation={false}>
      <Header />
      <CheckoutProgress step={Pages.Details} />
      <h1 className="text-xl text-center lg:text-4xl md:text-4xl text-mtn-blue-900 font-bold ">Maximise your blessings!</h1>
      <CheckoutPage>
        <div className="font-semibold col-start-2 col-end-12 lg:col-start-1 lg:col-end-7 xl:col-end-8 flex flex-col items-stretch gap-y-4">

          <AutoEnroll error={autoEnrolError} setError={setAutoEnrolError} platform={Variant.mtn} />
          {componentPresentInExperiment(Component.FridayGiving) && renderFridayGivingBoxVersion()}
          {componentPresentInExperiment(Component.Wakf) && renderWakfBox()}
          <section className="mb-4">
            <label className="flex md:mt-3 items-center">
              <input
                type="checkbox"
                className="mr-4"
                data-test-id={MytennightsTestId.marketingOptIn_ + PaymentFormProps.emailOptIn}
                checked={emailOptIn}
                onClick={() => {
                  setEmailOptIn(!emailOptIn)
                  setPlan({ ...plan, emailOptIn: !emailOptIn })
                }}
              />
              <span className="text-opacity text-base font-thin">
                <span className='font-bold'>I’m a tech savvy donor!</span> Email me about MyTenNights’ projects, causes and news.</span>
            </label>
            <label className="flex items-center mt-3">
              <input
                type="checkbox"
                className="mr-4"
                data-test-id={MytennightsTestId.marketingOptIn_ + PaymentFormProps.charityEmailOptIn}
                checked={charityEmailOptIn}
                onClick={() => {
                  setCharityEmailOptIn(!charityEmailOptIn)
                  setPlan({ ...plan, charityEmailOptIn: !charityEmailOptIn })
                }}
              />
              <span className="text-opacity text-base font-thin"><span className=' capitalize'>{plan.charity.charity_name}</span> can contact me. Share my contact details with them.</span>
            </label>
          </section>
          <div className='flex w-full justify-center'>
            {renderContinueBtn()}
          </div>
        </div>

        <div className="hidden sm:block md:hidden col-start-2 col-end-12 lg:col-start-8 xl:col-start-9 lg:col-end-12 lg:flex lg:flex-row-reverse gap-y-8 ">
          <CheckoutSummaryCard fees={getTotalFees(plan, 0)}>
            {renderContinueBtn()}
            {autoEnrolError && <div className="mt-3 text-sm text-center text-mtn-red">
              {autoEnrollMTNErrorMsg}
            </div>}
            {fGError && <div className="mt-3 text-sm text-center text-mtn-red">
              {fGError}
            </div>}
            {wakfError && <div className="mt-3 text-sm text-center text-mtn-red">
              {wakfError}
            </div>}
          </CheckoutSummaryCard>
        </div>
      </CheckoutPage>
    </WithFooter>
  )
}
