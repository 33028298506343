import { type FC } from 'react'
import { CharityCard } from '~/components/CharityCard'
import { type CharityCampaign, useCheckoutPlan } from '@shamaazi/mytennights'

import { ComponentsTestId } from '@lib/testing'
import { Page, TaxDeductibleDisclaimer } from '@lib/components'

export const ShareLinkCharities: FC<{ onSelect: (charity: CharityCampaign) => void, charities: CharityCampaign[], partner?: string }> = ({ onSelect, charities, partner }) => {
  const { plan } = useCheckoutPlan()
  const multiCharity = charities.find(c => c.charity_id.startsWith('multi-charity') && c.country === plan.chainCodeCharity.country)
  const friendCharity = plan.chainCodeCharity
  let remainingCharities: CharityCampaign[] = []
  const filterForPartner = (charity: CharityCampaign): boolean => {
    if (partner) {
      return charity.partner.split(',').includes(partner)
    }
    return true
  }
  if (friendCharity && multiCharity) {
    remainingCharities = charities.filter(c => c.country === plan.chainCodeCharity.country).filter(filterForPartner).filter(c => c.charity_id !== friendCharity.charity_id).filter(c => c.charity_id !== multiCharity.charity_id)
  }
  const charityList = [multiCharity ?? friendCharity]
  if (multiCharity && multiCharity.charity_id !== friendCharity.charity_id) {
    charityList.push(friendCharity)
  }
  charityList.push(...remainingCharities)
  const charityCards = charityList.map((c) => {
    return c && <CharityCard key={c.charity_id} onSelect={() => { onSelect(c) }} charity={c} />
  })
  return (
    <Page id="donate" className="my-10 w-full" data-test-id={ComponentsTestId.charitySelectionPage}>
      <div className="flex flex-col items-center w-full">
        <h1 className="mb-3 text-2xl lg:text-2xl font-semibold text-mtn-blue-800">Choose your charity</h1>
        <TaxDeductibleDisclaimer />

      </div>
      <div className="flex justify-center mt-5 w-full">
        <div className="w-full mx-4 grid grid-cols-1 gap-20 place-content-center sm:grid-cols-auto-sm ">
          {charityCards}
        </div>
      </div>
    </Page>

  )
}
