import { type FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { Button, EmailInput } from '@lib/components'
import { subscribe, Products } from '@shamaazi/mytennights'

interface FormData {
  email: string
}

export const SubscribeForm: FC = () => {
  const [submitted, setSubmitted] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm<FormData>({ defaultValues: { email: '' } })
  const { isLoading, isError, mutate: onSubscribe } = useMutation(async (data: FormData): Promise<void> => {
    await subscribe(data?.email ?? '', [Products.myTenNights])
  }, {
    onSuccess: () => {
      setSubmitted(true)
    }
  })

  return <section className="sticky bottom-0 w-full py-10 bg-mtn-blue-800">
    <div className="mx-auto max-w-2xl">
      <div className="px-5 w-full text-center">
        <p className="text-lg font-medium text-white">Get a reminder to automate your donations next Ramadan</p>
      </div>
      {submitted && <div className="px-5 w-full text-center">
        <p className="text-lg font-medium text-white">Thank you for subscribing!</p>
      </div>}
      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      {!submitted && <form className="grid gap-y-5 gap-x-10 px-5 mt-5 md:grid-cols-fr-auto" onSubmit={handleSubmit((data) => onSubscribe(data))}>
        <div className="flex flex-col">
          <EmailInput variant="mtn" register={register} errors={errors.email} />
          <p
            className="mt-1 text-xs text-right text-mtn-red min-h-xs">{errors.email?.message ?? ''}{isError && 'Sorry! Something went wrong subscribing you!'}</p>
        </div>
        <div className="flex flex-col">
          <Button
            variant="mtn-inverted"
            disabled={isLoading}
            loading={isLoading}
          >
            REMIND ME
          </Button>
          <div className="min-h-xs">{/* hacky spacer */}</div>
        </div>
      </form>}
    </div>
  </section>
}
