import { type FC } from 'react'
import { Link } from 'react-router-dom'

import fundraising from './fundraising-regulator-white.png'
import { MTNtracking } from '@shamaazi/mytennights'
import { isWidget } from '@lib/services'

const Footer: FC<{ navigation: boolean }> = ({ navigation }) => {
  return <footer className="flex flex-col justify-between w-full px-10 py-8 footer-bg">
    <div className="flex items-center font-medium lg:items-start">
      <nav className="flex flex-col justify-between flex-grow mr-10 text-sm lg:flex-row">
        {navigation && !isWidget && <>
          <Link className="mb-4 hover:text-gm-yellow text-white" to="/">Home</Link>
          <a className="mb-4 hover:text-gm-yellow text-white" target="_blank" rel="noopener noreferrer" href="https://articles.mytennights.com">Articles</a>
          <Link className="mb-4 hover:text-gm-yellow text-white" to="/about-us" onClick={() => MTNtracking.aboutUs()}>About Us</Link>
          <Link className="mb-4 hover:text-gm-yellow text-white" to="/fees">Fees</Link>
          <Link className="mb-4 hover:text-gm-yellow text-white" to="/evergive">About Ever Give</Link>
          <Link className="mb-4 hover:text-gm-yellow text-white" to="/faq" onClick={() => MTNtracking.faq()}>FAQ</Link>
          <Link className="mb-4 hover:text-gm-yellow text-white" to="/terms-and-conditions">Terms &amp; Conditions</Link>
          <Link className="mb-4 hover:text-gm-yellow text-white" to="/privacy-policy">Privacy Policy</Link>
          <Link className="mb-4 hover:text-gm-yellow text-white" to="/cookie-preferences">Cookie Preferences</Link>
          <Link className="mb-4 hover:text-gm-yellow text-white" to="/contact">Contact Us</Link>
          <a className="hover:text-gm-yellow text-white mb-4" href="https://charity.shamaazi.com" target="_blank" rel="noopener noreferrer">Charity Registration</a>
        </>}
        <div className="text-sm text-left text-white">
          &copy; Copyright {new Date().getFullYear()} MyTenNights.{navigation && <br />} All rights reserved.
        </div>
      </nav>
    </div>
    <div className="flex flex-col items-start mt-10 sm:flex-row">
      <img className="w-32 mb-10 mr-10 sm:mb-0" src={fundraising} alt="Fundraising Regulator" />
      {navigation && <div className="text-xs text-white">
        MyTenNights is a trading name of Shamaazi. <Link className="footer-links" to="/privacy-policy">Privacy Statement</Link>, <Link className="footer-links"
                                                                                                                                        to="/terms-and-conditions">Terms &amp; Conditions</Link>,
        Company
        Registration No: 10622013. <a className="footer-links" href="https://www.fundraisingregulator.org.uk/directory/shamaazi-ltd-ta-my-ten-nights"
                                      rel="noreferrer noopener"
                                      target="_blank"
      >Registered with Fundraising Regulator.</a> Head
        Office: 6-8 Great Eastern Street, London, United Kingdom, EC2A 3NT, United Kingdom. Disclaimer: Shamaazi is not
        affiliated with any external web sites. Shamaazi is not responsible for the content of external internet
        sites and any links from external web sites to this site do not constitute an endorsement of that site
        by Shamaazi.
      </div>}
    </div>
  </footer>
}

export const WithFooter: FC<{ navigation?: boolean }> = ({ navigation = true, children }) => {
  return <div className="min-h-screen flex flex-col">
    <main className="max-w-full flex-1">
      {children}
    </main>
    <Footer navigation={navigation} />
  </div>
}
