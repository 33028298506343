import pillarsPartnerLogoDark from '../../public/assets/partners/partner-header-pillars-lightbg.svg'
import pillarsPartnerLogoLight from '../../public/assets/partners/partner-header-pillars-darkbg.svg'
import ilmfeedPartnerLogoDark from '../../public/assets/partners/partner-header-ilmfeed-lightbg.svg'
import ilmfeedPartnerLogoLight from '../../public/assets/partners/partner-header-ilmfeed-darkbg.svg'
import ruhCarePartnerLogoDark from '../../public/assets/partners/partner-header-ruhcare-lightbg.svg'
import ruhCarePartnerLogoLight from '../../public/assets/partners/partner-header-ruhcare-darkbg.svg'
import mesutOzilPartnerLogoDark from '../../public/assets/partners/partner-header-ozil-lightbg.svg'
import mesutOzilPartnerLogoLight from '../../public/assets/partners/partner-header-ozil-darkbg.svg'

export class Partner {
  static pillars = 'pillars'
  static allowedPartners: Array<{
    name: string
    partnerLogoDark?: string
    partnerLogoLight?: string
  }> = [
      {
        name: Partner.pillars,
        partnerLogoDark: pillarsPartnerLogoDark,
        partnerLogoLight: pillarsPartnerLogoLight
      },
      {
        name: 'ilmfeed',
        partnerLogoDark: ilmfeedPartnerLogoDark,
        partnerLogoLight: ilmfeedPartnerLogoLight
      },
      {
        name: 'min'
      },
      {
        name: 'embold'
      },
      {
        name: 'hanaan'
      },
      {
        name: 'hamza-el-omar'
      },
      {
        name: 'nadia-allure'
      },
      {
        name: 'ali-bayrouti'
      },
      {
        name: 'amina-khan'
      },
      {
        name: 'arooba-kashif'
      },
      {
        name: 'ashley-pearson-khan'
      },
      {
        name: 'inaam-itani'
      },
      {
        name: 'sameen-aftab'
      },
      {
        name: 'sana-saleh'
      },
      {
        name: 'saira-khan'
      },
      {
        name: 'nabeah-wahab'
      },
      {
        name: 'sarah-ahmed'
      },
      {
        name: 'ruh-care',
        partnerLogoDark: ruhCarePartnerLogoDark,
        partnerLogoLight: ruhCarePartnerLogoLight
      },
      {
        name: 'mesut-ozil',
        partnerLogoDark: mesutOzilPartnerLogoDark,
        partnerLogoLight: mesutOzilPartnerLogoLight
      }
    ]
}
