import { type FC } from 'react'
import { useParams } from 'react-router-dom'
import { GivematchShareLinks, Header, WithFooter } from '~/components'

const ShareOptions: FC = () => {
  const { shareCode } = useParams() as { shareCode: string }

  return <WithFooter>
    <Header />
    <GivematchShareLinks shareCode={shareCode} isDashboard={false} />
  </WithFooter>
}

export default ShareOptions
