import { type FC } from 'react'
import { GivematchShareLinks } from '~/components'
import closeButton from './close.svg'

const ShareLinkModal: FC<{ shareCode: string, isDashboard: boolean, lengthChoice?: string, splitChoice?: string, giftAid?: boolean, wakf?: boolean, wakfAmount?: number, fridayGiver?: boolean, fridayGiverAmount?: number, hideModal: () => void }> = ({ shareCode, hideModal, isDashboard, lengthChoice, splitChoice, giftAid, wakf, wakfAmount, fridayGiver, fridayGiverAmount }) => {
  return <div
    className="bg-white fixed z-50 transform transition-all -translate-x-2/4 -translate-y-2/4 top-1/2 left-1/2 w-full h-full md:max-w-xl md:max-h-700 shadow-md rounded">
    <button className="fixed top-2 right-2 p-4 cursor-pointer" onClick={hideModal}>
      <img className="w-4" src={closeButton} alt="Givematch" />
    </button>
    <GivematchShareLinks shareCode={shareCode} isDashboard={isDashboard} lengthChoice={lengthChoice} splitChoice={splitChoice} giftAid={giftAid} wakf={wakf} wakfAmount={wakfAmount} fridayGiver={fridayGiver} fridayGiverAmount={fridayGiverAmount} />
  </div>
}

export default ShareLinkModal
