import { useEffect } from 'react'

export const hideWidget = (): void => window?.zE?.('webWidget', 'hide')
export const showWidget = (): void => window?.zE?.('webWidget', 'show')

export const useHiddenZendeskWidget = (): void => {
  useEffect(() => {
    hideWidget()
    return () => showWidget()
  }, [])
}
