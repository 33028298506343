import { withAuth } from '@lib/hooks'
import { type CharityCampaign } from '@shamaazi/mytennights'
import { callService } from '@lib/services'
import { HttpRequestMethod } from 'http-core-constants'

// Fire and forget endpoint for opting a donor into Givematch
export const optIntoGivematch = withAuth(async (shareCode: string, isVoucherEligible: boolean, voucherType: string): Promise<void> => {
  const res = await fetch(`api/givematch/${shareCode}`,
    {
      method: 'POST',
      body: JSON.stringify({ is_voucher_eligible: isVoucherEligible, voucher_type: voucherType })
    })

  if (res.status !== 200) {
    throw new Error('Unable to opt donor into Givematch')
  }
})

export const getCharityFromDonation = withAuth(async (shareCode: string): Promise<CharityCampaign> => {
  return callService(`/api/givematch/${shareCode}/charity`, HttpRequestMethod.GET)
})
