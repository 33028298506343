import { type Dispatch, type FC, type SetStateAction, useState, type ReactElement } from 'react'

import { Card, type CharityCampaign, MTNtracking } from '@shamaazi/mytennights'
import { MytennightsTestId } from '@lib/testing'
import { useAuth } from '@lib/hooks'
import { isMultiCharity } from '@lib/services'
import multiCharityLogo from '~/img/where-most-needed.jpg'
import { useCheckoutPlan } from '@shamaazi/mytennights'

const coverURL = (charityID: string): string => `/assets/charities/${charityID}/${charityID}_cover.svg`

interface props {
  charity: CharityCampaign
  onSelect: () => void
  setHasCoverImage?: Dispatch<SetStateAction<boolean>>
}

export const CharityCard: FC<props> = ({ charity, onSelect, setHasCoverImage }) => {
  const { user } = useAuth()
  const [logoExist, setLogoExist] = useState(true)
  const { plan } = useCheckoutPlan()
  const causes = charity.causes.map(c => {
    return <li key={c.cause_id} className="lowercase">
      <span className='font-semibold text-lg'>•</span> <span className=' capitalize'> {c.title} </span>
    </li>
  })

  const generateImage = (charity: CharityCampaign): ReactElement => {
    if (isMultiCharity(charity.charity_id)) {
      return <div className='w-full flex flex-col justify-center items-center text-2xl gap-y-5'>
      <img className='rounded-lg object-cover h-52 w-full' src={multiCharityLogo} alt={charity.charity_id}/>
    </div>
    } else {
      return (logoExist ? <img className="rounded-lg w-full h-52 object-cover bg-white" src={coverURL(charity.charity_id)} alt={charity.charity_id} onError={() => {
        setLogoExist(false)
        setHasCoverImage?.(false)
      }}
      /> : <div className='w-full h-52 flex flex-col rounded-lg justify-center items-center text-2xl gap-y-5 bg-white'>
        <img src='/assets/charities/fallback.svg' alt={charity.charity_id} className='bg-white' />
        <p className='text-mtn-blue text-center'>{charity.charity_name}</p>
      </div>)
    }
  }

  return <Card
    data-test-id={MytennightsTestId.charityCard_ + charity.charity_id}
    variant="mtn" className="flex flex-col duration-300 cursor-pointer charity-card-bg shadow-none">
    <div data-test-id={MytennightsTestId.charityCardSelectButton_ + charity.charity_id}
      onClick={() => {
        MTNtracking.selectCharity(user?.email, charity.charity_name, charity.charity_id, MTNtracking.getNightsLengthChoice(plan.totalNight.value), plan.split)
        onSelect()
      }}>
      {generateImage(charity)}

      <div className="flex flex-col flex-grow p-4">
        <h4 className="text-2xl text-mtn-blue-700 font-bold"> {isMultiCharity(charity.charity_id) ? 'Where Most Needed' : charity.charity_name}</h4>
        <div className='flex flex-col text-sm text-mtn-grey-300'>
          {isMultiCharity(charity.charity_id) && <p className="flex-grow w-3/4 "> Spread your donations across multiple charites chosen by us.</p>}
          <ul className="flex-grow w-full">
            {causes}
          </ul>
        </div>
      </div>

    </div>

  </Card>
}
