import { type FC } from 'react'
import { Link } from 'react-router-dom'

import { LoginForm, Page } from '@lib/components'
import { WithFooter } from '~/components'
import { MTNtracking } from '@shamaazi/mytennights'
import { Header } from './header/Header'

export const Login: FC = () => {
  return <WithFooter>
    <Header />
    <Page className="py-10 px-10 font-medium" skinny>
      <h1 className="mb-10 text-3xl text-mtn-blue-800">Login</h1>
      <p className="text-lg">Already made a donation?</p>
      <p className="mt-5 text-lg">Login to check your payment schedule</p>
      <LoginForm
        className="mt-8"
        fontVariant="mtn"
        buttonVariant="mtn-primary"
        trackingSignInEmail={(e) => MTNtracking.signInEmail(e)}
        trackingSignInCode={(e) => MTNtracking.signInCode(e)} />
      <p className="mt-20">If you can&apos;t log in, please <Link className="text-mtn-blue underline" to="/contact">contact us</Link></p>
    </Page>
  </WithFooter>
}
