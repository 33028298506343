import { type FC } from 'react'
import { Link } from 'react-router-dom'
import { Page } from '@lib/components'
import { Header, WithFooter } from '~/components'

export const ErrorPage: FC = () => <WithFooter>
  <Header />
  <div className="my-6 mx-4">
    <Link className="underline text-mtn-blue-700" to="/">&lt; Go Home</Link>
  </div>
  <Page className="mt-10" skinny>
    <h1 className="text-xl font-medium font-bold text-mtn-blue-800">This page could not be found</h1>
  </Page>
  <div className="my-6 text-center">
    <h1 className="text-lg font-bold text-mtn-blue-800">Sorry, we were unable to fulfill that command!</h1>
    <p>Our service team have been notified!</p>
  </div>
</WithFooter>
