import { type FC, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { DateTime } from 'luxon'
import { Button, InputWithRef, Page, RadioButton, Spinner, Variant } from '@lib/components'
import { displayCurrency, getCurrencySymbol, isCampaignInCurrentYear, penniesToPound } from '@lib/services'
import {
  Card,
  loadSchedule,
  NotFoundError,
  PaymentStatus as Status,
  type Schedule as ScheduleType,
  setCancelled,
  setNight30,
  MTNtracking,
  PaymentFormProps
  , saveSchedule, type UpdateScheduleFormData,
  updateAutoEnroll,
  GivematchShareSection,
  fetchCheckoutSession,
  getStripe,
  ActiveCampaign,
  updateGiftAid,
  canScheduleBeAutoEnrolled
} from '@shamaazi/mytennights'
import { Header, WithFooter } from '~/components'

import { useAuth } from '@lib/hooks'
import { MytennightsTestId, ScheduleTestIds } from '@lib/testing'
import { getPaymentStatusColor, NightBreakdown } from '~/pages/profile/nightBreakdown'
import { useForm } from 'react-hook-form'
import moment from 'moment'
export const Schedule: FC = () => {
  const { scheduleID } = useParams() as { scheduleID: string }
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const {
    isLoading,
    isError,
    error,
    data: schedule
  } = useQuery<ScheduleType>(['schedules', scheduleID], async () => await loadSchedule(scheduleID), { retry: 0 })
  const { mutate: toggleNight30, isError: night30Error, isLoading: night30Loading } = useMutation(async (val: boolean): Promise<ScheduleType> => {
    if (val === schedule?.night30) {
      return schedule
    }
    return await setNight30(scheduleID, val)
  }, {
    onSuccess: (data) => {
      queryClient.setQueryData(['schedules', scheduleID], data)
      queryClient.invalidateQueries('schedules').catch(() => { })
    }
  })
  const {
    mutate: setScheduleState,
    isError: isCancelError,
    isLoading: isCancelLoading
  } = useMutation(async (): Promise<ScheduleType> => await setCancelled(scheduleID, !(schedule?.cancelled ?? true)), {
    onSuccess: (data: ScheduleType) => {
      if (data.cancelled) {
        MTNtracking.cancelDonation(user?.email, data.charity_name, data.charity_id, data.total, data.currency, data.causes)
      } else {
        MTNtracking.reactivateDonation(user?.email, data.charity_name, data.charity_id, data.total, data.currency, data.causes)
      }

      queryClient.setQueryData(['schedules', scheduleID], data)
    }
  })
  const {
    mutate: setAutoEnrollState,
    isError: isAutoEnrollCancelError,
    isLoading: isAutoEnrollCancelLoading
  } = useMutation(async (): Promise<ScheduleType> => await updateAutoEnroll(scheduleID, !(schedule?.auto_enroll ?? true)), {
    onSuccess: (data: ScheduleType) => {
      // TODO tracking

      queryClient.setQueryData(['schedules', scheduleID], data)
    }
  })
  const {
    mutate: setGiftAidState,
    isError: isGiftAidCancelError,
    isLoading: isGiftAidCancelLoading
  } = useMutation(async (): Promise<ScheduleType> => await updateGiftAid(scheduleID, !(schedule?.giftAid ?? true)), {
    onSuccess: (data: ScheduleType) => {
      queryClient.setQueryData(['schedules', scheduleID], data)
    }
  })
  const handleClick = async (): Promise<void> => {
    if (!schedule) {
      return
    }
    try {
      // Call your backend to create the Checkout session.
      const sessionId: string = await fetchCheckoutSession(scheduleID, schedule)
      // When the customer clicks on the button, redirect them to Checkout.
      const stripe = await getStripe(schedule.charity_id)
      const result = await stripe?.redirectToCheckout({ sessionId })
      if (result?.error) {
        console.error('Error redirecting to Checkout:', result.error)
      }
    } catch (error) {
      console.error('Error handling payment:', error)
    }
  }

  const handleClickWrapper = (): void => {
    handleClick().catch(error => {
      console.error('Error handling payment:', error)
    })
  }

  const isScheduleEditable = (): boolean => {
    const isCloned = schedule?.cloned_from_schedule_id && schedule?.cloned_from_schedule_id !== ''
    if (!isCloned) {
      return false
    }
    const firstNight = schedule.payments[0].date.toString()
    // if first night date is before now, then first night is yet to pass
    const firstNightPassed = moment(firstNight).isBefore(moment())

    return !firstNightPassed
  }
  const form = useForm<UpdateScheduleFormData>()
  const { register, handleSubmit, reset } = form

  const {
    mutate: onSubmit,
    isError: isSaveScheduleError,
    isLoading: isSaveScheduleLoading
  } = useMutation(async (formData: UpdateScheduleFormData) => await saveSchedule(scheduleID, formData), {
    onSuccess: (data) => {
      queryClient.setQueryData(['schedules', scheduleID], data)
      queryClient.invalidateQueries('schedules').catch(() => { })
    }
  })

  useEffect(() => {
    const defaultValues: Record<string, number> | undefined = schedule?.causes.reduce((obj, item) => ({
      ...obj,
      [item.cause_id]: penniesToPound(item.amount)
    }), {})

    reset({
      campaign: schedule?.campaign,
      cause_amounts: defaultValues,
      tip_amount: penniesToPound(schedule?.tip ?? 0),
      pay_fees: schedule?.payFees
    })
  }, [reset, schedule])

  const night30 = ((): { amount: number, date: DateTime, bgColor: string, textColor: string, status: Status } => {
    const night29 = schedule?.payments?.find((n) => n.number === 29)
    const payment30 = schedule?.payments?.find((n) => n.number === 30)
    const status = payment30?.status ?? Status.Pending
    const colors = getPaymentStatusColor(status)
    return {
      amount: schedule?.night30Amount ?? 0,
      date: night29?.date?.plus({ days: 1 }) ?? DateTime.utc(),
      status,
      bgColor: colors.bg,
      textColor: colors.text
    }
  })()
  const splitBreakdown = (schedule?.payments?.filter(({ number }) => number !== 30) ?? []).map((payment) => <NightBreakdown scheduleID={scheduleID} schedule={schedule!}
    payment={payment} />)

  function onNight30Change (value: boolean): void {
    MTNtracking.preferencesUpdated(user?.email, { night30: value })
    toggleNight30(value)
  }
  return <WithFooter>
    <Header />
    <div>
      <div className="mx-6 mt-10">
        <Link className="underline" to="/account/schedules">&lt; Back to My Donations</Link>
      </div>
      <Page data-test-id={ScheduleTestIds.schedulePage} className="px-10 py-10 font-medium lg:grid lg:gap-x-20 lg:grid-cols-fr-auto">
        <div>
          <h1 className="text-xl text-mtn-blue">Schedule</h1>
          {(isLoading || isSaveScheduleLoading) && <div className="flex justify-center mt-20">
            <Spinner />
          </div>}
          {isError && error instanceof NotFoundError && <div className="mt-10">
            <h2>Sorry, we couldn&apos;t find this donation.<br />Please check you are logged in as the correct email address</h2>
          </div>}
          {isError && !(error instanceof NotFoundError) && <div className="mt-10">
            <h2>Sorry, we weren't able to load your schedule. Please try again later and if
              the issue persists, <Link className="underline text-mtn-blue" to="/contact">contact us</Link>
            </h2>
          </div>}
          {!isLoading && !isError && schedule !== undefined && <>
            <p className="my-4 text-mtn-blue-800">Created
              at {schedule?.created?.toLocaleString(DateTime.TIME_24_SIMPLE)} on {schedule?.created?.toLocaleString(DateTime.DATE_MED)}</p>
            {schedule.cancelled && <p className="my-2 text-sm text-mtn-red">Cancelled</p>}
            <hr className="text-mtn-gray-300" />
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <form onSubmit={handleSubmit((data) => onSubmit(data))}>
              <section className="grid items-center my-10 gap-y-6 gap-x-10 grid-cols-auto-fr">

                <span className="text-sm">Charity</span>
                <span className="text-right text-mtn-blue-800">{schedule.charity_name}</span>
                <span className="self-start mt-2 text-sm">Causes</span>
                <div>
                  {schedule.causes.map(c => <div key={c.name} className="grid mt-2 gap-x-6 grid-cols-fr-auto">
                    <span className="text-right flex items-center justify-end">{c.name}</span>
                    {isScheduleEditable()
                      ? <div> {getCurrencySymbol(schedule.currency)}<InputWithRef
                        variant="mtn"
                        className="ml-2 text-right w-44"
                        placeholder="0.00"
                        step="0.01"
                        type="number"
                        inputMode="decimal"
                        {...register(`cause_amounts.${c.cause_id}`)} />
                      </div>
                      : <span className="text-right text-mtn-blue-800">{displayCurrency({ amount: c.amount, currency: schedule.currency })}</span>
                    }
                  </div>)}
                </div>
                <span className="text-sm">MyTenNights Donation</span>
                <span className="text-right">
                  {isScheduleEditable()
                    ? <div> {getCurrencySymbol(schedule.currency)}<InputWithRef
                      variant="mtn"
                      className="ml-2 text-right w-44"
                      placeholder="0.00"
                      step="0.01"
                      type="number"
                      inputMode="decimal"
                      {...register('tip_amount')} />
                    </div>
                    : <span className="text-right text-mtn-blue-800">{displayCurrency({ amount: schedule.tip, currency: schedule.currency })}</span>
                  }
                </span>
                <span className="text-sm">Transaction Fees</span>
                <div className='text-right'>
                  {isScheduleEditable() && <input
                    type="checkbox"
                    className="mr-6 sm:mr-4"
                    data-test-id={MytennightsTestId.marketingOptIn_ + PaymentFormProps.annualReminderOptIn}
                    {...register('pay_fees')} />}
                  <span className=" text-mtn-blue-800">{displayCurrency({
                    amount: schedule.payFees ? schedule.fee : 0,
                    currency: schedule.currency
                  })}</span>
                </div>
                <hr className="col-span-2 text-mtn-gray-300" />
                <span className="text-sm">Total</span>
                <span className="font-bold text-right text-mtn-blue-800">{displayCurrency({ amount: schedule.total, currency: schedule.currency })}</span>

                {isScheduleEditable() &&
                  <div className='flex col-start-2 justify-end'>
                    <Button
                      variant="gm-widget"
                      className="w-1/2"
                      loading={isSaveScheduleLoading} disabled={isSaveScheduleLoading}>
                      Save
                    </Button>
                    {isSaveScheduleError && <p className="text-sm text-mtn-red">Sorry, we were unable to update your schedule amounts</p>}
                  </div>}
              </section>
            </form>
            <GivematchShareSection schedule={schedule} platform={Variant.mtn} />
            <hr className="col-span-2 my-10 text-mtn-gray-300" />
            <section>
              <h2 className="text-mtn-blue">Here&apos;s Your Breakdown</h2>
              <div className="grid gap-3 mt-10 grid-cols-auto-min-30 md:grid-cols-5 lg:grid-cols-auto-min-30 xl:grid-cols-5">
                {splitBreakdown}
              </div>
            </section>
            <section className="mt-10">
              <h2 className="mb-4">If Ramadan lasts 30 nights, what would you like to do?</h2>
              <div className="grid gap-3 grid-cols-auto-min-30 md:grid-cols-5 lg:grid-cols-auto-min-30 xl:grid-cols-5">
                <div className="flex flex-col items-center justify-center font-medium text-center border-2 border-dashed rounded h-60 border-mtn-blue-200">
                  <span
                    className={`${schedule.night30 ? 'bg-mtn-blue' : 'bg-mtn-blue-200'} transition-colors duration-500 h-14 w-14 rounded-full text-white text-xl flex items-center justify-center`}>30</span>
                  <span className={`text-sm my-3 ${night30.textColor}`}>{night30.date.toLocaleString({
                    weekday: 'short',
                    month: 'long',
                    day: 'numeric'
                  })}</span>
                  {(night30?.status ?? Status.Pending) !== Status.Pending &&
                    <span className={`${night30?.textColor} text-sm mb-3`}>Payment {night30?.status}</span>}
                  <span className="text-mtn-blue-800">{displayCurrency({ amount: night30.amount, currency: schedule.currency })}</span>
                  {(night30?.status ?? Status.Pending) !== Status.Pending &&
                    <Link to={`/account/schedules/${scheduleID}/night/30`} className="mt-2 text-xs underline">View Details</Link>}
                </div>
                <p className="col-span-2 mt-10 text-xs">
                  You&apos;ve opted {!schedule.night30 ? <i>not</i> : ''} to donate if Ramadan runs for 30 nights.<br /><br /><br />
                  <strong>Note:<br /></strong>
                  We&apos;ll only take your donation if there is a 30th night. We&apos;ll email you a reminder if this happens.
                </p>
              </div>
              {!night30Loading && night30.status === Status.Pending && <>
                <label className="flex items-center my-6 cursor-pointer">
                  <RadioButton
                    variant="mtn"
                    data-test-id={ScheduleTestIds.lastNight_ + 'false'}
                    name="night30"
                    onChange={() => onNight30Change(false)}
                    checked={!schedule.night30}
                  />
                  I don&apos;t want to donate on the 30th night
                </label>
                <label className="flex items-center my-6 cursor-pointer">
                  <RadioButton
                    variant="mtn"
                    data-test-id={ScheduleTestIds.lastNight_ + 'true'}
                    name="night30"
                    onChange={() => onNight30Change(true)}
                    checked={schedule.night30}
                  />
                  I&apos;d like to donate on the 30th night
                </label>
              </>}
              {night30Loading && <div className="flex justify-center my-10"><Spinner /></div>}
              {night30Error && <p className="text-sm text-mtn-red">Sorry, we were unable to update your preference</p>}
            </section>
          </>}
        </div>
        <div className="flex flex-col">
          {isLoading && <section className="flex items-center justify-center p-7"><Spinner /></section>}
          {!isLoading && !isError && schedule !== undefined && <>
            <Card variant="mtn" className="mt-20 -mx-6 lg:mx-0 lg:w-96 md:mt-0">
              {schedule.giftAid && <section className="p-7">
                <div className="mt-3">You have <span className=' font-bold'>opt-in</span> for giftaid.</div>
              </section>}
              {!schedule.giftAid && <section className="p-7">
                <div className="mt-3">You chose <span className=' font-bold'>not to opt-in</span> for giftaid.</div>
              </section>}
              {isCampaignInCurrentYear(schedule.campaign) && <section className="flex justify-center border-t p-7 border-mtn-gray-300">
                {schedule.giftAid && <Button loading={isGiftAidCancelError} disabled={isGiftAidCancelLoading} onClick={() => setGiftAidState()} variant="mtn-tertiary"
                  className="text-mtn-red">OPT-OUT FOR GIFTAID</Button>}
                {!schedule.giftAid &&
                  <Button loading={isGiftAidCancelLoading} disabled={isGiftAidCancelLoading} onClick={() => setGiftAidState()} variant="mtn-tertiary">OPT-IN FOR GIFTAID</Button>}
                {isGiftAidCancelError && <p className="text-sm text-mtn-red">Sorry, we were unable to do this</p>}
              </section>}
            </Card>
            <Card variant="mtn" className="mt-4 -mx-6 lg:mx-0 lg:w-96">
              {schedule.cardBrand !== '' && <section className="p-7">
                <div>Paying with card: {schedule.cardBrand} ending {schedule.last4CardDigits}</div>
                <div className="mt-3">Expires {schedule.cardExpiry}</div>
              </section>}
              {schedule.cardBrand === '' && <section className="p-7">
                <div>Paying with unknown card</div>
              </section>}
              {schedule.actionable && <section className="flex justify-center border-t p-7 border-mtn-gray-300">
                {!schedule.cancelled && <Button loading={isCancelLoading} disabled={isCancelLoading} onClick={() => setScheduleState()} variant="mtn-tertiary"
                  className="text-mtn-red">CANCEL DONATIONS</Button>}
                {schedule.cancelled &&
                  <Button loading={isCancelLoading} disabled={isCancelLoading} onClick={() => setScheduleState()} variant="mtn-primary">REACTIVATE</Button>}
                {isCancelError && <p className="text-sm text-mtn-red">Sorry, we were unable to do this</p>}
              </section>}
            </Card>
            <Card variant="mtn" className="mt-4 -mx-6 lg:mx-0 lg:w-96">
              {schedule.cardBrand !== '' && <section className="p-7">
                <div>You are currently <span className=' font-bold'>{schedule.auto_enroll ? ' opt-in ' : 'not opt-in'}</span>  for automation schedule for next year </div>
              </section>}
              {canScheduleBeAutoEnrolled(schedule, ActiveCampaign.myTenNights) && <section className="flex justify-center border-t p-7 border-mtn-gray-300">
                {schedule.auto_enroll && <Button loading={isAutoEnrollCancelLoading} disabled={isAutoEnrollCancelLoading} onClick={() => setAutoEnrollState()} variant="mtn-tertiary"
                  className="text-mtn-red">CANCEL NEXT YEAR SCHEDULE </Button>}
                {!schedule.auto_enroll &&
                  <Button loading={isAutoEnrollCancelLoading} disabled={isAutoEnrollCancelLoading} onClick={() => setAutoEnrollState()} variant="mtn-tertiary">OPT-IN FOR NEXT YEAR SCHEDULE</Button>}
                {isAutoEnrollCancelError && <p className="text-sm text-mtn-red">Sorry, we were unable to do this</p>}
              </section>}
            </Card>
            {(ActiveCampaign.myTenNights === schedule.campaign || isCampaignInCurrentYear(schedule.campaign)) && (
              <Card variant="mtn" className="mt-4 -mx-6 lg:mx-0 lg:w-96">
                <section className="flex justify-center border-t p-7 border-mtn-gray-300">
                  <Button id='checkout-button' variant="mtn-tertiary" onClick={handleClickWrapper}>UPDATE PAYMENT DETAILS</Button>
                </section>
              </Card>
            )}
          </>}
        </div>
      </Page>
    </div>
  </WithFooter>
}
