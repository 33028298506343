import { type FC, useState } from 'react'
import Clipboard from 'react-clipboard.js'

import copy from './copy.svg'
import whatsapp from './whatsapp.svg'
import twitter from './twitter.svg'
import facebook from './facebook.svg'
import email from './email.svg'
import sms from './sms.jpg'
import telegram from './telegram.svg'
import ShareTracker from '../ShareTracker'
import { ComponentsTestId } from '@lib/testing'
import { UrlRoutes } from '@lib/services'

const generateTwitterShareLink = (message: string): string => {
  return `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`
}

const generateFacebookShareLink = (shareUrl: string, message: string): string => {
  return `https://www.facebook.com/share.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(message)}`
}

const generateWhatsAppShareLink = (message: string): string => {
  return `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`
}

const generateTelegramShareLink = (message: string): string => {
  return `https://telegram.me/share/url?text=${encodeURIComponent(message)}`
}

const generateSMSShareLink = (message: string): string => {
  return `sms://;?&body=${encodeURIComponent(message)}`
}

const generateEmailShareLink = (message: string, subject: string): string => {
  return `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`
}

const ClipBoard: FC<{ shareCode: string, isDashboard: boolean, lengthChoice?: string, splitChoice?: string, giftAid?: boolean, wakf?: boolean, wakfAmount?: number, fridayGiver?: boolean, fridayGiverAmount?: number }> = ({ shareCode, isDashboard, lengthChoice, splitChoice, giftAid, wakf, wakfAmount, fridayGiver, fridayGiverAmount }) => {
  const [tooltipClass, setTooltipClass] = useState('hidden')
  const shareUrl = UrlRoutes.buildShareUrl(shareCode)
  return <Clipboard
    component="a"
    className="block z-10 cursor-pointer"
    data-clipboard-text={shareUrl}
    data-test-id={ComponentsTestId.inviteLinkCopyButton}
    onSuccess={() => setTooltipClass('')}>
    <ShareTracker type="Copy" isDashboard={isDashboard} url={shareUrl} lengthChoice={lengthChoice} splitChoice={splitChoice} giftAid={giftAid} wakf={wakf} wakfAmount={wakfAmount} fridayGiver={fridayGiver} fridayGiverAmount={fridayGiverAmount}>
      <div className="p-3 mt-4 bg-white rounded-lg border-2 border-solid border-gm-green">
        <div
          className="flex items-center"
          onMouseOut={() => { setTooltipClass('hidden') }}
          onTouchEnd={() => setTimeout(() => { setTooltipClass('hidden') }, 3000)}>
          <img
            src={copy}
            alt="copy"
            className="w-8 h-8"
          />
          <div className={tooltipClass}>
            <div className="block absolute mt-4 text-sm">
              <div className="py-1 px-2 text-center text-white rounded-lg bg-gm-green-900">Copied!</div>
            </div>
          </div>
          <div className="flex-1 ml-4 text-base">
            Copy
          </div>
        </div>
      </div>
    </ShareTracker>
  </Clipboard>
}

export const GivematchShareLinks: FC<{ shareCode: string, isDashboard: boolean, lengthChoice?: string, splitChoice?: string, giftAid?: boolean, wakf?: boolean, wakfAmount?: number, fridayGiver?: boolean, fridayGiverAmount?: number }> = ({ shareCode, isDashboard, lengthChoice, splitChoice, giftAid, wakf, wakfAmount, fridayGiver, fridayGiverAmount }) => {
  const shareLink = UrlRoutes.buildShareUrl(shareCode)
  const message = `I’ve automated my donations on MyTenNights, so I never miss giving on Laylatul Qadr!\n\nThis year, they’ve partnered with Givematch to double donations, giving you twice the reward on Laylatul Qadr.\n\n${shareLink}\n\nDonate through my link to catch Laylatul Qadr, and then double your reward too!`

  interface props {
    label: string
    link: string
    image: string
    isDashboard: boolean
    className?: string
  }

  const ShareRow: FC<props> = ({ label, link, image, className, isDashboard }) => {
    return <ShareTracker type={label} isDashboard={isDashboard} url={link}>
      <div className={`p-3 ${className ?? ''}`}>
        <a href={link}
           className="no-underline text-gm-green-900"
           target="_blank"
           rel="noopener noreferrer">
          <div className="flex items-center">
            <img className="w-8 h-8 rounded-lg" src={image} alt={label} />
            <div className="flex-1 ml-4 text-base">
              {label}
            </div>
          </div>
        </a>
      </div>
    </ShareTracker>
  }

  return <div className="py-12 px-8 mx-auto max-w-xl font-medium font-givematch text-gm-green-900">
    <div className="mt-4 bg-white rounded-lg border-2 border-solid border-gm-green">
      <ShareRow
        label={'WhatsApp'}
        link={generateWhatsAppShareLink(message)}
        image={whatsapp}
        isDashboard={isDashboard}
      />
    </div>

    <ClipBoard shareCode={shareCode} isDashboard={isDashboard} lengthChoice={lengthChoice} splitChoice={splitChoice} giftAid={giftAid} wakf={wakf} wakfAmount={wakfAmount} fridayGiver={fridayGiver} fridayGiverAmount={fridayGiverAmount}/>

    <div className="mt-4 bg-white rounded-lg border-2 border-solid border-gm-green">
      <ShareRow
        label={'Facebook'}
        link={generateFacebookShareLink(shareLink, message)}
        image={facebook}
        isDashboard={isDashboard}
      />

      <ShareRow
        className="border-r-0 border-l-0 border-t-2 border-b-2 border-solid border-gm-green"
        label={'Telegram'}
        link={generateTelegramShareLink(message)}
        image={telegram}
        isDashboard={isDashboard}
      />

      <ShareRow
        label={'Email'}
        link={generateEmailShareLink(message, 'Giving counts more on Givematch')}
        image={email}
        isDashboard={isDashboard}
      />
    </div>

    <div className="mt-4 bg-white rounded-lg border-2 border-solid border-gm-green">
      <ShareRow
        className="border-t-0 border-r-0 border-l-0 border-b-2 border-solid border-gm-green "
        label={'SMS'}
        link={generateSMSShareLink(message)}
        image={sms}
        isDashboard={isDashboard}
      />

      <ShareRow
        label={'Twitter'}
        link={generateTwitterShareLink(message)}
        image={twitter}
        isDashboard={isDashboard}
      />
    </div>
  </div>
}

export const ShareLinks: FC<{ shareCode: string }> = ({ shareCode }) => {
  const shareLink = UrlRoutes.buildShareUrl(shareCode)
  const message = `I'm all set for Laylatul Qadr! With MyTenNights, I automated my donations over the last ten nights, so I don't miss the special night. You can catch it too right here:\n\n${shareLink}`

  interface props {
    label: string
    link: string
    image: string
  }

  const ShareRow: FC<props> = ({ link, label, image }) => {
    return <span className="py-2 px-4">
      <a href={link}
         className="no-underline"
         target="_blank"
         rel="noopener noreferrer">
        <img className="w-10 h-10 rounded-lg" src={image} alt={label} />
      </a>
    </span>
  }

  return <>
    <h2 className="font-bold text-mtn-blue-800">Now help your friends catch Laylatul Qadr too</h2>
    <div className="flex flex-wrap justify-center items-center mt-4">
      <ClipBoard shareCode={shareCode} isDashboard={false} />

      <ShareRow
        label={'WhatsApp'}
        link={generateWhatsAppShareLink(message)}
        image={whatsapp} />

      <ShareRow
        label={'Facebook'}
        link={generateFacebookShareLink(shareLink, message)}
        image={facebook} />

      <ShareRow
        label={'Telegram'}
        link={generateTelegramShareLink(message)}
        image={telegram} />

      <ShareRow
        label={'Email'}
        link={generateEmailShareLink(message, 'The easiest way to catch Laylatul Qadr')}
        image={email} />

      <ShareRow
        label={'SMS'}
        link={generateSMSShareLink(message)}
        image={sms} />

      <ShareRow
        label={'Twitter'}
        link={generateTwitterShareLink(message)}
        image={twitter} />
    </div>
  </>
}
