import { type ChangeEvent, type FC, type ReactElement, useState } from 'react'
import { CharityCard } from '~/components/CharityCard'
import { ActiveExperiments, type CharityCampaign, MTNtracking } from '@shamaazi/mytennights'
import { Country, getUserCountry } from '@lib/services'
import { useAuth } from '@lib/hooks'
import { ComponentsTestId } from '@lib/testing'
import { CountrySelection, Input, Page, SearchIcon, TaxDeductibleDisclaimer } from '@lib/components'
import { WithFooter } from './footer'
import { TestVersion, useCheckoutPlan } from '@shamaazi/mytennights'
import { Experiment, Variant } from '@lib/react-ab-test'

export const HomePageCharities: FC<{ onSelect: (charity: CharityCampaign) => void, charities: CharityCampaign[], partner?: string }> = ({ onSelect, charities, partner }) => {
  const countries = [...new Set(charities?.map(c => c.country))]
  const [country, setCountry] = useState(getUserCountry())
  const [searchState, setSearchState] = useState('')
  const { user } = useAuth()
  const { plan } = useCheckoutPlan()
  const onSearchTextChanged = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchState(e.target.value)
  }

  const filterCharities = (charity: CharityCampaign): boolean => {
    const searchTerm = searchState.trim().toLowerCase()
    return charity.charity_name.toLowerCase().includes(searchTerm) || charity.charity_id.toLowerCase().includes(searchTerm) ||
      charity.causes?.find(c => c.title.toLowerCase().includes(searchTerm)) !== undefined
  }
  const filterForPartner = (charity: CharityCampaign): boolean => {
    if (partner) {
      return charity.partner.split(',').includes(partner)
    }
    return true
  }
  const charitiesFromUK: string[] = [
    'orphans-in-need-uk',
    'muslim-aid-uk'
  ]

  const pennyAppealUkCharityId: string = 'penny-appeal-uk'
  // We are adding some big charity names from the UK into the American, Canadian and Australian homepage to boost credibility in those countries
  const filterUKCharityIntoOtherCountries = (): CharityCampaign[] => {
    if (country === Country.US || country === Country.CA || country === Country.AU) {
      return charities.filter(c => charitiesFromUK.includes(c.charity_id))
    }
    return []
  }

  const filterOutPennyAppealUk = (charity: CharityCampaign): boolean => {
    return !charity.charity_id.toLowerCase().includes(pennyAppealUkCharityId)
  }

  const pennyAppealUkCharityCampaign = (withPennyAppeal: boolean): CharityCampaign[] => {
    return withPennyAppeal && country === Country.GB ? charities.filter(c => pennyAppealUkCharityId === c.charity_id) : []
  }

  const renderCharityCards = (pennyAppealUk: boolean): ReactElement => {
    return <>
      {charities?.filter((c) => c.country === country).concat(...filterUKCharityIntoOtherCountries()).filter(filterOutPennyAppealUk).concat(pennyAppealUkCharityCampaign(pennyAppealUk)).filter(filterCharities).filter(filterForPartner).map((c) => {
        return <CharityCard key={c.charity_id} onSelect={() => onSelect(c)} charity={c} />
      })}
      </>
  }

  return <WithFooter navigation={false}>
    <Page id="donate" className="md:mb-10 justify-center" data-test-id={ComponentsTestId.charitySelectionPage}>
      <div className="md:flex md:justify-center">
        <div className="flex flex-col items-center w-full md:w-1/2">
          <div className='w-full px-4 text-center'>
            <h1 className="mb-10 text-2xl lg:text-4xl font-semibold text-mtn-blue-800">Pick a charity to support</h1>
            <CountrySelection
              countries={countries}
              value={country}
              className="w-full max-w-full pl-12"
              onCountryChange={(v: Country) => {
                setCountry(v)
                MTNtracking.countryChange(user?.email, v, MTNtracking.getNightsLengthChoice(plan.totalNight.value), plan.split)
              }}
              variant="mtn"

            />
            <div className="mt-4 relative w-full">
              <SearchIcon />
              <Input variant="mtn" value={searchState} onChange={onSearchTextChanged}
                placeholder="Find a charity or cause" className="w-full max-w-full pl-12" />
            </div>
          </div>
        </div>
      </div>

      <TaxDeductibleDisclaimer />
      <div className="flex justify-between lg:mx-36 my-5">
        <div className="w-full mx-4 md:mx-10 grid grid-cols-1 gap-4 place-content-center lg:grid-cols-3 md:grid-cols-2 sm:gap-4">
          <Experiment name={ActiveExperiments.CharityPennyAppealUK}>
            <Variant name={TestVersion.control}>
              {renderCharityCards(true)}
            </Variant>
            <Variant name={TestVersion.variant}>
              {renderCharityCards(false)}
            </Variant>
          </Experiment>

        </div>
      </div>
    </Page>
  </WithFooter>
}
