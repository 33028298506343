import { Card } from '@shamaazi/mytennights'
import { type FC, useState } from 'react'

export const InfoBox: FC = ({ children }) => {
  const [showFeeTooltip, setShowFeeTooltip] = useState(false)
  return <>
    <div
      tabIndex={0}
      onFocus={() => setShowFeeTooltip(true)}
      onBlur={() => setShowFeeTooltip(false)}
      className="inline-flex items-center justify-center w-5 h-5 ml-3 p-3 font-semibold text-sm text-mtn-blue-900 rounded-full cursor-pointer focus:outline-none bg-mtn-gray-800"
    >
      i
    </div>
    {showFeeTooltip && <Card variant="mtn" className="absolute p-3 text-xs bg-white font-medium bottom-full">
      {children}
    </Card>}
  </>
}
