import { Link } from 'react-router-dom'
import { type FC } from 'react'
import { type Payment, PaymentStatus, type Schedule, MTNtracking } from '@shamaazi/mytennights'
import { Currency, displayCurrency, type PartialRecord } from '@lib/services'
import { useAuth } from '@lib/hooks'
import { ScheduleTestIds } from '@lib/testing'

interface statusColor {
  bg: string
  text: string
}

const paymentStatusColors: PartialRecord<PaymentStatus, statusColor> = {
  [PaymentStatus.Skipped]: { bg: 'bg-mtn-blue-200', text: 'text-mtn-blue-200' },
  [PaymentStatus.Cancelled]: { bg: 'bg-mtn-gray', text: 'text-mtn-red' },
  [PaymentStatus.Failed]: { bg: 'bg-mtn-red', text: 'text-mtn-red' },
  [PaymentStatus.Succeeded]: { bg: 'bg-mtn-blue', text: 'text-mtn-blue' }
}

export const getPaymentStatusColor = (status: PaymentStatus): { bg: string, text: string } => {
  return paymentStatusColors[status] ?? { bg: 'bg-mtn-gray', text: 'text-mtn-gray' }
}

interface NightBreakdownProps {
  scheduleID: string
  schedule: Schedule
  payment: Payment
}

export const NightBreakdown: FC<NightBreakdownProps> = ({ scheduleID, schedule, payment }) => {
  const { user } = useAuth()

  const color = getPaymentStatusColor(payment.status)

  return <div
    data-test-id={`${ScheduleTestIds.night_}${payment.number}`}
    key={payment.number}
    className={`${payment.status === PaymentStatus.Skipped ? 'opacity-80' : ''} h-60 font-medium bg-mtn-gray-300 rounded flex flex-col items-center justify-center text-center`}>
    <span className={`${color.bg} h-14 w-14 rounded-full text-white text-xl flex items-center justify-center`}>{payment.number}</span>
    <span className={`${color.text} text-xs mt-3`}>{payment.date.toLocaleString({ weekday: 'short', month: 'long', day: 'numeric' })}</span>
    <span className={`${color.text} text-sm my-3`}>Payment {payment.status}</span>
    <span className={color.text}>{payment.status !== PaymentStatus.Skipped ? displayCurrency({
      amount: payment.total,
      currency: schedule?.currency ?? Currency.GBP
    }) : 'Unavailable'}</span>
    {payment.status !== PaymentStatus.Skipped && <Link to={`/account/schedules/${scheduleID}/night/${payment.number}`} className="mt-2 text-xs underline"
                                                       onClick={() => MTNtracking.viewScheduleDetails(user?.email)}>View Details</Link>}
  </div>
}
