import { type FC, type FormEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card } from '@shamaazi/mytennights'
import { Button, Page } from '@lib/components'
import { useLocalStorage } from '@lib/hooks'
import { Header, WithFooter } from '~/components'

export const CookiePreferences: FC = () => {
  const [hasAccepted, setHasAccepted] = useLocalStorage<boolean | null>('cookie-banner', null)
  const [preference, setPreference] = useState(hasAccepted ?? false)
  const [updating, setUpdating] = useState(false)

  const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    setUpdating(true)
    setHasAccepted(preference)
    window.location.reload()
  }

  return <WithFooter>
    <Header />
    <Page skinny className="mt-10">
      <Card variant="mtn" className="my-8 mx-4 font-medium bg-white">
        <section className="p-10 border-b border-mtn-gray-300">
          <h2 className="text-lg text-mtn-blue-800">Cookie Preferences</h2>
          <p className="mt-4 text-sm">For more information on how we use cookies to improve your experience on our site please read our privacy policy <Link
            className="underline text-mtn-blue-800" to="/privacy-policy">here</Link>.</p>
        </section>

        <section className="p-10 border-b border-mtn-gray-300">
          <form onSubmit={onSubmit}>
            <label className="flex items-center mt-4 cursor-pointer">
              <input
                className="mr-4"
                type="radio"
                name="preference"
                onChange={() => setPreference(true)}
                checked={preference ?? false} />
              Allow all cookies
            </label>
            <label className="flex items-center mt-4 cursor-pointer">
              <input
                className="mr-4"
                type="radio"
                name="preference"
                onChange={() => setPreference(false)}
                checked={!(preference ?? false)} />
              Allow strictly necessary cookies only
            </label>
            <Button
              variant="mtn-primary"
              className="mt-8"
              type="submit"
              loading={updating}
              disabled={updating}>
              Update
            </Button>
          </form>
          <p className="mt-8 text-xs">If you would like to opt out of all cookies please adjust the settings within your browser. For instructions check your browser's technical
            information. By disabling all cookies you will not be able to use essential features of our site such as logging in to your account.</p>
        </section>
      </Card>
    </Page>

  </WithFooter>
}
