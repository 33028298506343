import { type FC } from 'react'
import { Header, WithFooter } from '~/components'
import EGlogo from '~/img/EG logo.svg'
import EGWorks from '~/img/EG tree graphic.png'
import EGGraph from '~/img/Evergive graph.png'
export const EverGivePage: FC = () => {
  return <WithFooter>
        <Header transparent isEverGive={true} />
        <div className="bg-bottom bg-no-repeat bg-cover ever-give">
            <div className="w-9/12 md:w-2/4 flex justify-center">
                <img src={EGlogo} className="w-full md:w-3/5" alt="EG Logo" />
            </div>
        </div>
        <div className='eg-color py-12 px-5 md:p-12 flex flex-col justify-center'>
            <div className='text-white eg-text font-thin text-center mb-10'>What is EverGive?</div>
            <p className='text-white pb-4 font-light eg-sub-text self-center text-center md:w-2/5'>EverGive is an initiative by the founder of MyTenNights, where your donations are invested and the profits go to charity each year, so your impact lasts forever</p>
        </div>
        <div className='flex items-center justify-center'>
            <div className='py-12 px-5 md:px-0 flex flex-col gap-y-7 justify-center md:w-2/5 text-gm-black eg-body-text font-light'>
                <div>During Ramadan, some of you subscribed to a monthly donation to our Waqf product called <span className='font-bold'>EverGive</span></div>
                <div>The Prophet (pbuh) taught us to give to charity in a way where our rewards never stop after we die - i.e. to invest our donations into revenue generating assets, where the profits go to charity each year.</div>
                <img className='my-5' src={EGWorks} alt="Ever give" />
                <div>We believe modern giving has overlooked this glorious tradition, and together we can seek to revive this sunnah. Did you know, investing just £100 into charity this way with an 8% annual return can yield about £220,000 for charity in 100 years, all on your scale of deeds after you're gone.</div>
                <div>Your monthly donation will be invested into a range of Shariah compliant assets, managed at the discretion of our highly qualified board of investment professionals. We will update you on the progress of the investments and the profits that go to charity each year.</div>
                <div>Your first payment will be leaving your account on the 1st of June 2024. If you have any questions, please email <a className="underline" href="mailto:contact@evergive.com">contact@evergive.com</a> and we will happily assist you.</div>
                <div>For example, if you invested £100 today, in 100 years your donation could grow to £220k!</div>
                <img className='my-5' src={EGGraph} alt='Ever give graph' />
                <div>For further details please email <a className="underline" href="mailto:contact@evergive.com">contact@evergive.com</a></div>
            </div>
        </div>
    </WithFooter>
}
