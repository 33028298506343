import { useAuth } from '@lib/hooks'
import { type FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { MTNtracking } from '@shamaazi/mytennights'
import { isWidget } from '@lib/services'

import defaultLogoWhite from './logo-white.svg'
import defaultLogo from './logo.svg'
import WhiteLogo from './logo-all-white.svg'
import { Button } from '@lib/components'
import { HeaderTestIds } from '@lib/testing'
import { Partner } from '~/service/partners'

const useScrolled = (): boolean => {
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const onScroll = (): void => {
      if (window.pageYOffset > 0) {
        setScrolled(true)
        return
      }

      setScrolled(false)
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return scrolled
}

export const Header: FC<{ transparent?: boolean, showProfileLink?: boolean, isEverGive?: boolean }> = ({ transparent = false, showProfileLink = false, isEverGive = false }) => {
  const scrolled = useScrolled()
  const [active, setActive] = useState(false)
  const { user, logout } = useAuth()
  let logo = defaultLogo
  let logoWhite = defaultLogoWhite
  let landingUrl = '/'

  const pos = transparent ? 'fixed' : 'sticky top-0'
  const inverted = transparent && !scrolled
  if (scrolled) {
    isEverGive = false
  }
  const cn = inverted ? 'bg-transparent' : 'bg-white border-b-1 border-mtn-blue'
  const links = inverted ? 'text-white hover:text-mtn-blue-200' : 'text-mtn-blue-800 hover:text-mtn-blue'
  const mobileLink = inverted ? 'border-mtn-blue-800' : 'border-mtn-gray-300'
  if (MTNtracking.getPartner().partner) {
    const partnerInfo = Partner.allowedPartners.find(p => p.name === MTNtracking.getPartner().partner)
    logo = partnerInfo?.partnerLogoDark ?? defaultLogo
    logoWhite = partnerInfo?.partnerLogoLight ?? defaultLogoWhite
    landingUrl = `/partner/${MTNtracking.getPartner().partner}`
  }

  function onLogoutClick (): void {
    MTNtracking.logOut(user!.email)
    setActive(false)
    logout() // eslint-disable-line @typescript-eslint/no-floating-promises
  }

  return <>
    <header className={`z-50 px-6 sm:px-20 items-center py-4 w-full font-medium flex justify-between transition-colors duration-500 ease-in-out ${cn} ${pos}`}>
      {!isWidget && <Link to={landingUrl}
                          onClick={() => MTNtracking.home(user?.email)}><img className="py-3.5 w-56" alt="MyTenNights" src={isEverGive ? WhiteLogo : (inverted ? logoWhite : logo)} /></Link>}
      {isWidget && <img className="py-3.5 w-56" alt="MyTenNights" src={isEverGive ? WhiteLogo : (inverted ? logoWhite : logo)} />}
      {!isWidget && showProfileLink && <>
        <nav className="hidden md:block">
          <Link
            data-test-id={HeaderTestIds.myProfile}
            className={`${links} ml-10 transition-colors duration-500`} to="/account/schedules" onClick={() => MTNtracking.myProfile(user?.email)}>MY
            PROFILE</Link>
          {user !== null && <Link
            data-test-id={HeaderTestIds.logout}
            onClick={() => onLogoutClick()}
            className={`${links} ml-10 transition-colors duration-500`}
            to={landingUrl}
          >
            LOG OUT
          </Link>}
          {/* {isCampaignOpen() && <Link className="ml-10" to={{ hash: '#donate', pathname: '/' }}>
            <Button variant={inverted ? 'mtn-donate' : 'mtn-donate-inverted'} onClick={() => onDonateNowClick(ButtonLocation.TopBar, user?.email ?? '')}> DONATE </Button>
          </Link>} */}
        </nav>
        <div className={`md:hidden tham tham-w-6 mtn-grey-800 ${active ? 'tham-active' : ''}`} onClick={() => setActive(!active)}>
          <div className="tham-box">
            <div className={`tham-inner ${inverted ? 'bg-white' : 'bg-mtn-blue-800'} `} />
          </div>
        </div>
      </>}
    </header>
    <nav
      className={`md:hidden fixed min-h-screen w-full z-40 font-medium transform transition-all duration-500 left-full ${active ? '-translate-x-full' : ''} ${inverted ? 'bg-mtn-blue-900' : 'bg-white'}`}>
      <div className={`${transparent ? 'mt-20' : ''} flex flex-col px-8`}>
        {/* {isCampaignOpen() && <Link
          onClick={() => setActive(false)}
          className={`${links} ${mobileLink} py-14 border-b w-full text-center transition-colors duration-500`}
          to={{ hash: '#donate', pathname: '/' }}>
          <Button variant="mtn-primary" className="border-8" onClick={() => onDonateNowClick(ButtonLocation.TopBar, user?.email ?? '')}> DONATE </Button>
        </Link>} */}
        <Link
          onClick={() => {
            MTNtracking.myProfile(user?.email)
            setActive(false)
          }}
          className={`${links} ${mobileLink} py-14 border-b w-full text-center transition-colors duration-500`}
          to="/account/schedules">
          <Button variant="mtn-primary" className="border-8">MY PROFILE</Button>
        </Link>
        {user !== null && <Link
          onClick={() => onLogoutClick()}
          className={`${links} ${mobileLink} py-14 border-b w-full text-center transition-colors duration-500`}
          to={landingUrl}
        >
          LOG OUT
        </Link>}
      </div>
    </nav>
  </>
}
